import React, { useState, useEffect } from "react";
import "./Nav.css";
import { Link } from "react-router-dom";
import logo from "../../resource/logo.png";
import Brochure from "../../resource/PDF/Brochure.pdf";

const Nav = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    // if (window.innerWidth >= 769) {
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollBtn = document.getElementById("mybutton");
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        scrollBtn.style.display = "block";
      } else {
        scrollBtn.style.display = "none";
      }
    }

    scrollBtn.onclick = function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
  }, []);

  useEffect(() => {
    let Menu_open = document.querySelector(".menu_btn");
    let Mobile_menu = document.querySelector(".phone_menu_item");
    Menu_open.onclick = function () {
      Mobile_menu.classList.toggle("phone_menu_item_active");
      Menu_open.classList.toggle("fa-xmark");
    };
  });

  return (
    <>
      <div className="head">
        <div className="container">
          <div className="nav-items">
            <div className="head_left">
              <Link to="/Career/">Career</Link>
              <Link to="/OurCentre/">Our Centres</Link>
              <Link to="/FAQ/">FAQ</Link>
              <div className="head_left_report_tag">
                Reports &nbsp;{" "}
                <i
                  style={{ color: "#fff" }}
                  className="fa-solid changei fa-caret-down"
                ></i>
                <div className="AnnualyReports">
                  <Link to="/QuarterlyReports/">Quarterly Reports</Link>
                  <Link to="/AnnualyReports/">Annualy Reports</Link>
                </div>
              </div>
            </div>
            <div className="head_right">
              <Link to="tel:03335081849/">
                <i className="fa-solid fa-headset"></i>
                <span style={{ color: "white" }}>
                  Customer Grievance Number:{" "}
                </span>
                <span className="icon_padding" style={{ color: "white" }}>
                  (033) 3508-1849
                </span>
              </Link>

              <i className="fa-solid fa-grip-lines-vertical"></i>

              <Link
                to="https:///mail.google.com/mail/?view=cm&fs=1&to=contactus@sampurna.co.in"
                target="_blank"
              >
                <i className="fa-regular fa-envelope"></i>
                <span className="icon_padding">contactus@sampurna.co.in</span>
              </Link>

              <i className="fa-solid fa-grip-lines-vertical"></i>

              <Link to="tel:6292276444/">
                <i className="fa-solid fa-phone"></i>
                <span className="icon_padding">+91 62922-76444</span>
              </Link>

              <i className="fa-solid fa-grip-lines-vertical"></i>

              <Link className="brochure" to={Brochure} target="_blank">
                Download Brochure
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id="mybutton" className="scroll_top">
        <i className="fa-solid fa-arrow-up"></i>
      </div>

      <nav className={isSticky ? "nav fixed" : "nav"}>
        <div className="container">
          <div className="brand">
            <Link to="/">
              <img className="logowhite" src={logo} alt="Sampurna Skills" />
            </Link>
          </div>
          <div className="menu">
            <ul>
              <li className="menu_item_">
                <Link className="menu_link">About Us</Link>
                <i className="fa-solid changei fa-caret-down"></i>
                <ul>
                  <li>
                    <Link to="/Overview/">Overview</Link>
                  </li>
                  <li>
                    <Link to="/Vision/">Vision & Mission</Link>
                  </li>
                  <li>
                    <Link to="/TargetPeople/">Target People</Link>
                  </li>
                  <li>
                    <Link to="/Background/">Organization Background</Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link className="menu_link">Our Courses</Link>
                <i className="fa-solid changei fa-caret-down"></i>
                <ul>
                  <li>
                    <Link to="/Computer/">Computer</Link>
                  </li>
                  <li>
                    <Link to="/MakeupBeauty/">Beauty & Makeup</Link>
                  </li>
                  <li>
                    <Link to="/DressDesigining/">Dress Designing</Link>
                  </li>
                  <li>
                    <Link to="/SpokenEnglish/">Spoken English</Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link className="menu_link">Governance</Link>
                <i className="fa-solid changei fa-caret-down"></i>
                <ul>
                  <li>
                    <Link to="/Board/">Board</Link>
                  </li>
                  <li>
                    <Link to="/Management/">Management Team</Link>
                  </li>
                  <li>
                    <Link to="/GrievanceRedressal/">Grievance Redressal</Link>
                  </li>
                </ul>
              </li>
              <li className="menu_item_">
                <Link to="/Gallery/" className="menu_link">
                  Gallery
                </Link>
              </li>
              <li className="menu_item_">
                <Link to="/life-at-sampurna/" className="menu_link">
                  Life@Sampurna
                </Link>
              </li>
              <li className="menu_item_">
                <Link to="/Contact/" className="menu_link">
                  Get In Touch
                </Link>
              </li>
            </ul>
          </div>
          <div className="menu-btn">
            <i className="fa-solid menu_btn fa-bars"></i>
          </div>

          <div className="phone_menu_item">
            <Link>
              <strong>ABOUT US</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <Link to="/Overview/">Overview</Link>
            <Link to="/Vision/">Vision & Mission</Link>
            <Link to="/TargetPeople/">Target People</Link>
            <Link to="/Background/">Organization Background</Link>
            <Link>
              <strong>Our Courses</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <Link to="/Computer/">Computer</Link>
            <Link to="/MakeupBeauty/">Beauty & Makeup</Link>
            <Link to="/DressDesigining/">Dress Designing</Link>
            <Link to="/SpokenEnglish/">Spoken English</Link>
            <Link>
              <strong>GOVERNANCE</strong>
              <i className="fa-solid fa-caret-down"></i>
            </Link>
            <Link to="/Board/">Board</Link>
            <Link to="/Management/">Management Team</Link>
            <Link to="/GrievanceRedressal/">Grievance Redressal</Link>
            <Link to="/Gallery/">
              <strong>GALLERY</strong>
            </Link>
            <Link to="/life-at-sampurna/">
              <strong>Life@Sampurna</strong>
            </Link>
            <Link to="/Contact/">
              <strong>Get in Touch</strong>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
