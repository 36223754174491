import React, { useState, useEffect } from "react";
import "./QuarterlyReports.css";
import { Link } from "react-router-dom";

const QuarterlyReports = () => {
  const [showReports, setShowReports] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetch("https://manage.sampurnaskills.co.in/api/QuarterlyReports")
      .then((response) => response.json())
      .then((data) => {
        setReports(data);
        setShowReports(new Array(Math.ceil(data.length / 4)).fill(false));
      })
      .catch((error) => console.log(error));
  }, []);

  const toggleReports = (index) => {
    setShowReports((prevShowReports) => {
      const updatedShowReports = [...prevShowReports];
      updatedShowReports[index] = !prevShowReports[index];
      return updatedShowReports;
    });
  };

  const renderReports = () => {
    const renderedReports = [];
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i];
      if (report.name !== "" && report.pdfPath !=="" && report.imgPath !== "") {
        renderedReports.push(
          <Link to={report.pdfPath} target="_blank" className="reports" key={i}>
            <img src={report.imgPath} alt="pic" />
            <span>{report.name}</span>
          </Link>
        );
      }
      else{
        renderedReports.push(
        <div className="reports" key={i} style={{opacity:0 }}>
            <img src={report.imgPath} alt="pic" />
            <span>{report.name}</span>
          </div>
        )
      }
    }

    const renderedContainers = [];
    for (let i = 0; i < Math.ceil(renderedReports.length / 4); i++) {
      const start = i * 4;
      const end = start + 4;
      const yearStart = 2022 + i;
      const yearEnd = yearStart + 1;
      const yearRange = `${yearStart} - ${yearEnd}`;
      renderedContainers.push(
        <div className="year_container" key={i}>
          <div className="year_container_head">
            <h2>{yearRange}</h2>
            <i
              className="fa-solid fa-plus"
              onClick={() => toggleReports(i)}
            ></i>
          </div>
          <div
            className={`reportsec_container ${
              showReports[i] ? "show_reportsec_container" : ""
            }`}
          >
            {renderedReports.slice(start, end)}
          </div>
        </div>
      );
    }

    return renderedContainers;
  };

  return <div className="quarterlyreports container">{renderReports()}</div>;
};

export default QuarterlyReports;
