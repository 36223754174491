import React from "react";
import "./BoardComp.css";
import CeoPic from "../../resource/Board/Abhijit_sir.png";
import OperationHeadMF from "../../resource/Board/Arati_mam.png";
import OperationHeadVT from "../../resource/Board/Suparna_mam.png";

const BoardComp = () => {
  return (
    <>
      <section className="boardmembers">
        <div className="container">
          <div className="board">
            <div className="boardleft">
              <h1>Abhijit Bera</h1>
              <h2>CEO and Managing Director</h2>
              <p>
                Mr. Abhijit Bera is passionate and committed to find a balance
                between financial services and social impact. He is leading
                Sampurna for more than 12 years. In 2018 he has been awarded as
                the youngest leader in microfinance by AMFI-WB.
              </p>
            </div>
            <div className="boardright">
              <div className="img">
                <img src={CeoPic} alt="pic"/>
              </div>
            </div>
          </div>
          <div className="board">
            <div className="boardleft">
              <h1>Suparna Das</h1>
              <h2>Director & Operation Manager, Vocational Training</h2>
              <p>
                Ms. Suparna Das has more than 13 years of experience in
                microfinance and social- projects. Presently she is overseeing
                vocational training project. She has extensive knowledge in
                counseling. She has attended various training programs in India
                and abroad.
              </p>
            </div>
            <div className="boardright">
              <div className="img">
                <img src={OperationHeadVT} alt="pic" />
              </div>
            </div>
          </div>
          <div className="board">
            <div className="boardleft">
              <h1>Arati Shaw</h1>
              <h2>Director and Operation Manager (Partner Channel-ESAF Small Finance Bank)</h2>
              <p>
                Ms. Arati Shaw has more than 13 years of experience in
                microfinance. She has extensive knowledge about the context of
                slums. She is a key policy maker and has expertise in policy
                design and development. She has expertise in developing training
                modules on financial literacy and various community development
                issues.
              </p>
            </div>
            <div className="boardright">
              <div className="img">
                <img src={OperationHeadMF} alt="pic"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoardComp;
