import React, { useEffect } from 'react'
import VisionCom from '../Vision_component/VisionComp.js';
import HeroContent from '../Hero_component/Hero';


const Vision = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
    <HeroContent title="Vision & Mission"
        image={require("../../resource/Banner/vision.jpg")} />
      <VisionCom />
    </>
  )
}

export default Vision;
