import React from "react";
import "./TestimonialComp.css";
// import { Link } from "react-router-dom";

const TestimonialComp = () => {
  return (
    <>
      <div className="testimonial">
        <h1 className="sec_heading">Success Speaks</h1>
        <div className="container">
          <div className="testimonial_video">
            <iframe
              src="https://www.youtube-nocookie.com/embed/W1A-adDYuLc"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className="testimonial_video">
            <iframe
              src="https://www.youtube-nocookie.com/embed/d9mNuN7vclo"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className="testimonial_video">
            <iframe
              src="https://www.youtube-nocookie.com/embed/OA_VWXN_dP0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialComp;
