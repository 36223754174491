import React, { useEffect } from 'react';
import Hero from "../Hero_component/Hero.js";
import FAQComp from '../FAQ_component/FAQComp.js';


const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
    <Hero
        title="FAQ's"
        image={require("../../resource/Banner/faq.jpg")}
      />
      <FAQComp />
    </>
  )
}

export default FAQ
