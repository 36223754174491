import React, { useEffect } from 'react'
import HeroContent from '../Hero_component/Hero'
import CancelComp from '../Cancel_component/CancelComp'

const CancellationPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
        <HeroContent title="Cancellation Policy"
        image={require("../../resource/Banner/cancel.jpg")} />
        <CancelComp />
    </>
  )
}

export default CancellationPolicy