import React from "react";
import "./RefundComp.css";

const RefundComp = () => {
  return (
    <>
      <div className="container refund">
        <ul>
          <li>
            <h3>Introduction</h3>
            <p>
              This Course Refund Policy (the "Policy") outlines the guidelines
              and procedures for refunds of course fees at Sampurna Skills
              Development Pvt. Ltd. (referred to as "the Company" or "we"). We
              understand that circumstances may arise where learners need to
              seek a refund, and this Policy aims to provide clarity and
              fairness to both our learners and the Company.
            </p>
          </li>
          <li>
            <h3>Refund Eligibility</h3>
            <ul>
              <li>
                <h5>Withdrawal</h5>
                <p>
                  A learner may be eligible for a refund if they withdraw from a
                  course after 6 months of admission. This might occur if they
                  do not receive a class within this time period. b. To request
                  a refund, the learner must notify the Company in writing or by
                  email at least 180 days post to the date of admission. c. The
                  refund amount will be determined based on the Management
                  decision.
                </p>
              </li>
              <li>
                <h5>Untimely death </h5>
                <p>
                  If a learner has not started attending the course and meets
                  unfortunate fatality his/her family may be eligible for a
                  partial refund. b. The learner's family must submit a written
                  request within 30 days after the incident. c. The Company will
                  review the request and determine the refund amount, taking
                  into consideration the circumstances presented.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h3>Refund Process</h3>
            <ul>
              <li>
                <h5>Refund Request Submission </h5>
                <p>
                  To request a refund, the learner must submit a written request
                  to the Company's designated contact person. b. The request
                  must include the learner's full name, course details, reason
                  for refund, and any supporting documentation or evidence, if
                  applicable.
                </p>
              </li>
              <li>
                <h5>Refund Approval and Processing </h5>
                <p>
                  The Company will review the refund request and notify the
                  learner of the decision within 30 days of request submission.
                  b. If the refund request is approved, the Company will
                  initiate the refund process, which may take 45 days to
                  complete. c. Refunds will be issued using the same method of
                  payment as the original transaction unless otherwise agreed
                  upon by the Company and the learner.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h3>Modifications to the Policy</h3>
            <p>
              The Company reserves the right to modify or update this Course
              Refund Policy at any time. Any changes will be communicated to
              learners through our official communication channels and will be
              effective immediately upon posting.
            </p>
          </li>
        </ul>
        <p>
          By enrolling in a course with Sampurna Skills Development Pvt. Ltd.,
          learners acknowledge and agree to comply with this Course Refund
          Policy.{" "}
        </p>
      </div>
    </>
  );
};

export default RefundComp;
