import React, { useEffect } from 'react';
import Hero from "../Home_Hero_component/HomeHero.js";
import RoadmapComp from '../Home_RoadMap_component/RoadmapComp.js';
import CourseComp from '../Home_courses_component/CourseComp.js';
import WeProvideComp from '../Home_WhatWeProvide_component/WeProvideComp.js';
import TestimonialComp from '../Home_testimonial_component/TestimonialComp.js';
import HomeOverviewComp from '../Home_Overview_component/Home_OverviewComp.js';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
      <Hero />
      <HomeOverviewComp />
      <CourseComp />
      <WeProvideComp />
      <RoadmapComp />
      <TestimonialComp />
    </>
  )
}

export default Home;
