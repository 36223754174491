import React, { useEffect } from 'react'
import HeroContent from '../Hero_component/Hero';
import SpokenComp from '../SpokenEnglish_component/SpokenComp';

const Spoken = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
       <HeroContent
        title="Spoken English"
        image={require("../../resource/Banner/branch.jpeg")}
      />
      <SpokenComp />
    </>
  )
}

export default Spoken;
