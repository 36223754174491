import React from "react";
import Hero from "../Hero_component/Hero.js";
import LifeAtSSDComp from "../LifeAtSampurna/LifeAtSampurnaComp.js";

const LifeAtSSD = () => {
  return (
    <>
      <Hero
        title="Life At Sampurna"
        image={require("../../resource/Banner/career.jpg")}
      />
      <LifeAtSSDComp />
    </>
  );
};

export default LifeAtSSD;
