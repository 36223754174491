import React, { useEffect } from "react";
import CareerComp from "../Career_component/CareerComp.js";
import Hero from "../Hero_component/Hero.js";

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
      <Hero
        title="Career"
        image={require("../../resource/Banner/career.jpg")}
      />
      <CareerComp />
    </>
  );
};

export default Career;
