import React from 'react';
import "./BackgroundComp.css";

const BackgroundComp = () => {
  return (
    <>
      <section className="backgound container">
        <p>
         <span>Sampurna Skills Development Pvt Ltd</span> is an organization that had its informal beginnings in 2011, initially offering training options for loan partners. The organisation provided training in field of operation to enable partners with income opportunities. 
            <br />
            <br />
            <span>However, in 2017, </span> it transitioned into a formal entity and began providing vocational training courses. The organization focuses on four major domains (Computer, Makeup & Beauty, Dress Designing, Soft Skills) and offers a diverse range of 30+ courses. With its formal establishment, Sampurna Skills Development Pvt Ltd has expanded its scope and expertise, aiming to equip individuals with practical skills and knowledge in various vocational fields. Through its training programs, the organization aims to empower individuals and contribute to their professional growth and development.
        </p>
      </section>
    </>
  )
}

export default BackgroundComp;
