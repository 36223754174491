import React, { useEffect, useState } from "react";
import axios from "axios";

const MakeupComp = () => {
  const [coursedata, setCoursedata] = useState([]);
  const [contentVisibility, setContentVisibility] = useState([]);

  const loadcoursedata = async () => {
    const response = await axios.get("https://manage.sampurnaskills.co.in/api/Makeup");
    setCoursedata(response.data);
    setContentVisibility(new Array(response.data.length).fill(false));
  };

  useEffect(() => {
    loadcoursedata();
  }, []);

  const toggleContent = (index) => {
    setContentVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  return (
    <>
      <section className="makeup_content">
        <div className="container">
          <div className="domain_intro">
            <p>
              Sampurna offers a diverse range of makeup and beauty courses
              designed to provide participants with professional skills in the
              beauty industry. <br />
              The courses cover a wide spectrum of topics including skincare,
              makeup application, hair styling, nail art, mehndi design, and
              more. Participants will learn essential techniques, product
              knowledge, and industry trends to create various looks and provide
              beauty services. <br />
              With hands-on training, expert guidance, and a comprehensive
              curriculum, Sampurna's makeup and beauty courses aim to equip
              participants with the necessary skills and knowledge to pursue a
              successful career as makeup artists, hairstylists, beauty
              consultants, or entrepreneurs in the beauty industry.
            </p>
          </div>
          <div className="course_div">
            {coursedata.map((item, index) => {
              return (
                <article key={item.id} className="course">
                  <div className="course_content">
                    <div className="course_contant_header">
                      <h4>{item.name}</h4>
                      <div
                        className="course_icon"
                        onClick={() => toggleContent(index)}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </div>
                    </div>
                    <div
                      className={`course_content_details ${
                        contentVisibility[index] ? "show" : "hide"
                      }`}
                    >
                      <p className="course_intro">{item.course_info}</p>
                      <h3 className="critaria">Eligibility Criteria:</h3>
                      <p className="critaria_content">
                        {item.eligibility_critaria}
                      </p>
                      <div className="duration">
                        <h3>Course Duration: </h3>
                        <p>{item.course_duration}</p>
                      </div>
                      <div className="course-highlight">
                        <h3>Syllabus highlights: </h3>
                        <p>{item.syllabus_highlight}</p>
                      </div>
                      <div className="course-fees">
                        <h3>Fee structure: </h3>
                        <p>{item.fees_structure}</p>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default MakeupComp;
