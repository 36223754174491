import React from 'react';
import "./CenterComp.css";

const CenterComp = () => {
   
  return (
    <>

      <section className="branch_container">
        <div className="container">
            <table>
                <thead>
                    <tr>
                        <th>Branch Name</th>
                        <th>Branch Address</th>
                        <th>Branch Contact No.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Bagbazar</td>
                        <td>41B Ram Kanta Bose Street, Kolkata-700003, PS- Shyam Pukur</td>
                        <td>6292281724</td>
                    </tr>
                    <tr>
                        <td>Kadapara</td>
                        <td>P-128 CIT Road , Beliaghata, Kolkata-700010</td>
                        <td>8100973833</td>
                    </tr>
                    <tr>
                        <td>Bondel Gate</td>
                        <td>8A Bibhuti Bhusan Bandhopadhya Sarani, Kolkata-700 019</td>
                        <td>6292281726</td>
                    </tr>
                    <tr>
                        <td>Howrah Maidan</td>
                        <td>519/2 G.T. Road(South), Dist Howrah, Pin-711101</td>
                        <td>6292281728</td>
                    </tr>
                    <tr>
                        <td>Ghusuri</td>
                        <td>18, Gobinda Banerjee Lane, P.O : Salkia, P.S : Golabari Howrah-711106</td>
                        <td>6292281727</td>
                    </tr>
                    <tr>
                        <td>Kamarhati</td>
                        <td>SHIVALAYA,BL A, Second Floor, FL- 2A P.O - Kamarhati, P.S-Belghoria, Kolkata-700058</td>
                        <td>6292281729</td>
                    </tr>
                    <tr>
                        <td>Bidhan Nagar</td>
                        <td>2/1 of 92/2A, Bidhan Nagar Road, Kolkata-700067</td>
                        <td>6292281730</td>
                    </tr>
                    <tr>
                        <td>Seakhala</td>
                        <td>Biswas Para, Seakhala, P.O-Sehekhala, Hooghly-712706</td>
                        <td>8100973837</td>
                    </tr>
                    <tr>
                        <td>Chandrakona Town</td>
                        <td>Sat Bankura, Paschim Medinipur, West Bengal-721253</td>
                        <td>6292281713</td>
                    </tr>
                    <tr>
                        <td>Daspur</td>
                        <td>Vill - Goura, Block-Daspur II, P.O - Goura, P.S-Daspur Dist - Paschim Medinipur, West Bengal-721146</td>
                        <td>8100973831</td>
                    </tr>
                    <tr>
                        <td>Khirpai</td>
                        <td>Vill - KhIrpai HALDER DIGHI, Near Maa Sitala Hardware P.S-Chandrakona, Block-Chandrakona-I, Dist-Midnapur, West Bengal-721232</td>
                        <td>8100973834</td>
                    </tr>
                    <tr>
                        <td>Sabang</td>
                        <td>Barjiban, Pashim Medinipur, Kundalpal, WB-721140</td>
                        <td>6292281734</td>
                    </tr>
                    <tr>
                        <td>Kolaghat</td>
                        <td>Vill- Shantipur, PO- Mecheda,PS- Kolaghat, Pin-721137</td>
                        <td>8100973832</td>
                    </tr>
                    <tr>
                        <td>Medinipur Town</td>
                        <td>1085/236 Ramkrishna nagar ,Dharma , District- Paschim Medinipur, Pin Code : 721101</td>
                        <td>8100973835</td>
                    </tr>
                    <tr>
                        <td>Moyna</td>
                        <td>Vill-Srirampur(battala) Post- Srirampur, P.S- Tamluk, Pin-721651, Dist- East Medinipur</td>
                        <td>6292281715</td>
                    </tr>
                    <tr>
                        <td>Egra</td>
                        <td>village+ p.o Rasan,East Medinipur, 721429</td>
                        <td>6292281720</td>
                    </tr>
                    <tr>
                        <td>Keshiyari</td>
                        <td>Murakata, Keshiary, Keshiary ,Medinipur, West Bengal 721133</td>
                        <td>6292281722</td>
                    </tr>
                    <tr>
                        <td>Baruipur</td>
                        <td>506 Rishi Bankim Nagar, Baruipur, Pin-700144</td>
                        <td>6292281731</td>
                    </tr>
                    <tr>
                        <td>Laxmikantapur</td>
                        <td>Lakshikantapur (K), Bijoyganj Bazar, South 24 Parganas, West Bengal-743345</td>
                        <td>6292281733</td>
                    </tr>
                    <tr>
                        <td>Raidighi</td>
                        <td>Vill: Raidighi Sreefaltala Purbo, P.o & P.S Raidighi ,  South 24 Parganas, Pin- 743383</td>
                        <td>6292281732</td>
                    </tr>
                    <tr>
                        <td>Canning</td>
                        <td>Rammohan Pally, Dighirpar,Canning,South 24 Parganas,West Bengal 743329</td>
                        <td>8100973830</td>
                    </tr>
                    <tr>
                        <td>Diamond Harbour</td>
                        <td>VILL BHUSANA, P.O KAMARPOL,PS : PARULIA COASTAL,SOUTH 24 PARGANAS, WEST BENGAL -743368</td>
                        <td>6292281723</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
      </section>
    </>
  )
}

export default CenterComp;
