import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import ManagementComp from "../Management_component/ManagementComp.js";

function Management() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Management Team" image={require('../../resource/Banner/Management.jpeg')}/>
      <ManagementComp />
      </>
    );
  }
  
  export default Management;