import React, {useEffect, useState} from "react";
import "./WeProvideComp.css";
import axios from 'axios';

const WeProvideComp = () => {
  const [data, setData] = useState([]);
  const loadData = async()=>{
    const response = await axios.get("https://manage.sampurnaskills.co.in/api/get");
    setData(response.data);
  };
  useEffect(()=>{
    loadData();
  }, [])
  return (
    <>
      <section className="weprovide">
        <h1 className="sec_heading">What We Provide</h1>
        <div className="container">
          <div className="provide_left">
            <div className="card_icons">
            {data.map((item, index)=>{
              return (
                <div key={item.id} className="card_item">
                  <i className={item.icons}></i>
                  <span>{item.data}</span>
                </div>
              );
            })}
            </div>
            <div className="card_img">
              <div className="imgbox">
                <span>Computer</span>
              </div>
              <div className="imgbox">
                <span>Makeup & Beauty</span>
              </div>
              <div className="imgbox">
                <span>Dress Desigining</span>
              </div>
              <div className="imgbox">
                <span>Spoken English</span>
              </div>
            </div>
          </div>
          <div className="provide_right">
            <p>
              Presently, we have four operational topics namely&nbsp;
              <span className="highlight">
                Beauty, Computer, Spoken English and Apparel
              </span>
              &nbsp;with effective courses. <br /> <br />
              For Spoken English the eligible age group is 5 to 40 years whereas
              for the other courses, students can be between 14 to 40 years of
              age.
            </p>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default WeProvideComp;
