import React from 'react';
import "./OverviewComp.css";

const OverviewComp = () => {
  return (
    <>
      <section className="content container">
    <h1>Overview</h1>
    <div className="paragraph">
        <p>Sampurna Skills Development Pvt Ltd is a leading organization dedicated to providing comprehensive skill development programs. With a strong focus on enhancing employability and promoting entrepreneurship, Sampurna Skills Development offers a wide range of courses and training programs designed to equip individuals with practical skills for the job market.</p>
    </div>
    <div className="paragraph">
        <p>Sampurna Skills Development not only imparts technical skills but also fosters a holistic development approach, empowering individuals with the necessary soft skills, communication abilities, and entrepreneurship knowledge to succeed in their chosen field.</p>
    </div>
    <div className="paragraph">
        <p>As a socially conscious organization, Sampurna Skills Development also works towards the betterment of low-income families through various social initiatives and opportunities. By collaborating with local communities, NGOs, and government agencies, Sampurna endeavors to create a positive impact by providing skill-building opportunities, empowering individuals to break the cycle of poverty, and contributing to inclusive economic growth.</p>
    </div>
    <div className="paragraph">
        <p>With its commitment to excellence, affordability, and social impact, Sampurna Skills Development Pvt Ltd stands as a beacon of opportunity and empowerment for individuals seeking to enhance their skills and transform their lives.</p>
    </div>
</section>
    </>
  )
}

export default OverviewComp;
