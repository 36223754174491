import React, { useEffect } from 'react'
import HeroContent from '../Hero_component/Hero';
import BackgroundComp from '../Background_component/BackgroundComp';

const OrganizationBackground = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
       <HeroContent
        title="Organization Background"
        image={require("../../resource/Banner/Background.jpg")}
      />
      <BackgroundComp />
    </>
  )
}

export default OrganizationBackground;
