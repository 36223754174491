import React from "react";
import "./HomeHero.css";
import Typewriter from 'typewriter-effect';

const HomeComp = () => {
  return (
    <>
      <section className="home_hero">
        <div className="container">
          <div className="home_hero_content">
            <h1>Sampurna Skills Development PVT LTD</h1>
            <h3>Skill up with</h3>
            <span className="type">
              <Typewriter
                options={{
                  strings: ["Best Fashion Courses", "Best Makeup & Beauty Courses", "Best Computer & Peripherals Courses"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeComp;
