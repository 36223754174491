import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "./Components/Navbar_component/Nav.js";
import Footer from "./Components/Footer_component/Footer.js";
import Home from "./Components/Pages/Home.js";
import Career from "./Components/Pages/Career.js";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import FAQ from "./Components/Pages/FAQ";
import OurCenter from "./Components/Pages/OurCenter";
import Overview from "./Components/Pages/Overview";
import Vision from "./Components/Pages/Vision";
import TargetPeople from "./Components/Pages/TargetPeople";
import OrganizationBackground from "./Components/Pages/OrganizationBackground";
import Computer from "./Components/Pages/Computer";
import Makeup from "./Components/Pages/Makeup";
import Dress from "./Components/Pages/Dress";
import Spoken from "./Components/Pages/Spoken";
import Board from "./Components/Pages/Board";
import Management from "./Components/Pages/Management";
import Contact from "./Components/Pages/Contact";
import GrievanceRedressal from "./Components/Pages/GrievanceRedressal";
import Gallary from "./Components/Pages/Gallary";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import CancellationPolicy from "./Components/Pages/CancellationPolicy";
import QuarterlyReport from "./Components/Pages/QuarterlyReport";
import AnnualReport from "./Components/Pages/AnnualReport.js";
import RefundPolicy from "./Components/Pages/RefundPolicy.js";
import LifeAtSSD from "./Components/Pages/LifeAtSSD.js";

function App() {
  /** Disabling Right Click */
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default right-click behavior
    };

    // Add event listener to the document for the contextmenu event
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  /**Disabling Keyboard Shortcuts */

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Disable Ctrl+A (Cmd+A on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "a" || event.key === "A")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+S (Cmd+S on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "s" || event.key === "S")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+X (Cmd+X on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "x" || event.key === "X")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+C (Cmd+C on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "C")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+Shift+I (Cmd+Shift+I on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        (event.key === "i" || event.key === "I")
      ) {
        event.preventDefault();
      }
    };

    // Add event listener to the document for the keydown event
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts


  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <LayoutWrapper>
              <Home />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Career/"
          element={
            <LayoutWrapper>
              <Career />
            </LayoutWrapper>
          }
        />
        <Route
          path="/OurCentre/"
          element={
            <LayoutWrapper>
              <OurCenter />
            </LayoutWrapper>
          }
        />
        <Route
          path="/FAQ/"
          element={
            <LayoutWrapper>
              <FAQ />
            </LayoutWrapper>
          }
        />
        <Route
          path="/QuarterlyReports/"
          element={
            <LayoutWrapper>
              <QuarterlyReport />
            </LayoutWrapper>
          }
        />
        <Route
          path="/AnnualyReports/"
          element={
            <LayoutWrapper>
              <AnnualReport />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Overview/"
          element={
            <LayoutWrapper>
              <Overview />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Vision/"
          element={
            <LayoutWrapper>
              <Vision />
            </LayoutWrapper>
          }
        />
        <Route
          path="/TargetPeople/"
          element={
            <LayoutWrapper>
              <TargetPeople />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Background/"
          element={
            <LayoutWrapper>
              <OrganizationBackground />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Computer/"
          element={
            <LayoutWrapper>
              <Computer />
            </LayoutWrapper>
          }
        />
        <Route
          path="/MakeupBeauty/"
          element={
            <LayoutWrapper>
              <Makeup />
            </LayoutWrapper>
          }
        />
        <Route
          path="/DressDesigining/"
          element={
            <LayoutWrapper>
              <Dress />
            </LayoutWrapper>
          }
        />
        <Route
          path="/SpokenEnglish/"
          element={
            <LayoutWrapper>
              <Spoken />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Gallery/"
          element={
            <LayoutWrapper>
              <Gallary />
            </LayoutWrapper>
          }
        />

        <Route
          path="/life-at-sampurna/"
          element={
            <LayoutWrapper>
              <LifeAtSSD />
            </LayoutWrapper>
          }
        />

        <Route
          path="/Board/"
          element={
            <LayoutWrapper>
              <Board />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Management/"
          element={
            <LayoutWrapper>
              <Management />
            </LayoutWrapper>
          }
        />
        <Route
          path="/GrievanceRedressal/"
          element={
            <LayoutWrapper>
              <GrievanceRedressal />
            </LayoutWrapper>
          }
        />
        <Route
          path="/Contact/"
          element={
            <LayoutWrapper>
              <Contact />
            </LayoutWrapper>
          }
        />
        <Route
          path="/PrivacyPolicy/"
          element={
            <LayoutWrapper>
              <PrivacyPolicy />
            </LayoutWrapper>
          }
        />
        <Route
          path="/CancellationPolicy/"
          element={
            <LayoutWrapper>
              <CancellationPolicy />
            </LayoutWrapper>
          }
        />
        <Route
          path="/RefundPolicy/"
          element={
            <LayoutWrapper>
              <RefundPolicy />
            </LayoutWrapper>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

function LayoutWrapper({ children }) {
  const location = useLocation();
  const hideNavBarFooter = location.pathname === "*"; // Hide NavBar and Footer for 404 error page

  return (
    <div>
      {!hideNavBarFooter && <NavBar />}
      {children}
      {!hideNavBarFooter && <Footer />}
    </div>
  );
}

function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <h1>404 - Page Not Found</h1>
      <p>The requested page does not exist.</p>
      <p>
        Please return to the{" "}
        <Link
          style={{
            color: "var(--magenda)",
            textTransform: "uppercase",
            fontWeight: "600",
          }}
          to="/"
        >
          home page
        </Link>
        .
      </p>
    </div>
  );
}

export default App;
