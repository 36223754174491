import React from "react";
import "./GrievanceComp.css";

const GrievanceComp = () => {
  return (
    <>
      <div className="grievance container">
        <p className="grievance_intro">
          Any student can register a formal complaint in case of any grievance
          or for seeking any information or explanation. Grievance redressal
          policy:
        </p>
        <ul>
          <li>
            Each student will be provided the phone number of the Communication
            Department. The phone number as well as the email address of
            Communication Manager will be provided upon admission as per
            request. The student can register the complaint either over the
            phone or by sending an email to the Communication Manager.
          </li>
          <li>
            Any complaint by the student will be noted in the Complaint register
            to be maintained at the Head Office. The student should be provided
            information, clarification or action taken to the client within 30
            days. The Operation Manager (Skill Development Programme) will
            routinely check the complaint register and will cross-check with
            students whether the complaint has been addressed satisfactorily.
          </li>
          <li>
            Complaint register will be carried to field to register complaints
            by Branch Manager, Coordinator, OM and Internal auditor during
            monitoring and audits.
          </li>
          <li>
            The grievances noted at branch level will be monitored by the
            Operation Manager based at HO. The units will send a grievance
            redressal report every month to the Operation Manager.
          </li>
        </ul>
        <h3>Annexure I</h3>
        <h2>
          COMPLAINTS/SUGGESTIONS RECEIVED REGISTER TO BE MAINTAINED BY BRANCHES
        </h2>
        <h3>(To be maintained in soft copy)</h3>
        <span>Name of the Branch:</span>
        <div className="form_area">
          <div className="form_head">
            <p>Sl. No.</p>
            <p>Date of Receipt of the complaint/ suggestion</p>
            <p>Name of the Lodger</p>
            <p>Lodger Ph number.</p>
            <p>Gist of the complaint/ suggestion</p>
            <p>Date of final closure</p>
          </div>
          <div className="form_body">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
          <div className="form_body">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <p className="lastpara"><span>Complain box:</span> it is consisting of Name of coordinator, Phone number and time to contact, it could be good to have some comments about the objective of this box and how it can be utilized by the client.</p>
      </div>
    </>
  );
};

export default GrievanceComp;
