import React from "react";
import "./RoadmapComp.css";

const RoadmapComp = () => {
  return (
    <>
      <section className="roadmap">
        <h1 className="sec_heading">Key Milestone</h1>
        <div className="container">
          <div className="line">
            <div className="line_box">
              <i className="fa-solid fa-school"></i>
              <div className="innerline">
              <div className="roadmap_content">
                <ul>
                  <li>Company Formation</li>
                  <li>50+ Students</li>
                </ul>
              </div>
                <span>2017</span>
              </div>
            </div>
            <div className="line_box">
              <i className="fa-solid fa-building-wheat"></i>
              <div className="innerline">
                <div className="roadmap_content">
                <ul>
                  <li>15+ Centers</li>
                  <li>11+ Courses</li>
                </ul>
              </div>
                <span>2020</span>
              </div>
            </div>
            <div className="line_box">
              <i className="fa-solid fa-stamp"></i>
              <div className="innerline">
                <div className="roadmap_content">
                <ul>
                  <li>ISO Certified</li>
                  <li>MSME Certified</li>
                </ul>
              </div>
                <span>2022</span>
              </div>
            </div>
            <div className="line_box">
              <i className="fa-solid fa-person-chalkboard"></i>
              <div className="innerline">
                <div className="roadmap_content">
                <ul>
                <li>10K+ Students</li>
                  <li>15+ Trainers</li>
                </ul>
              </div>
                <span>2022</span>
              </div>
            </div>
            <div className="line_box">
              <i className="fa-solid fa-chalkboard-user"></i>
              <div className="innerline">
                <div className="roadmap_content">
                <ul>
                  <li>20+ Centers</li>
                  <li>40+ Trainers</li>
                </ul>
              </div>
                <span>2023</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoadmapComp;