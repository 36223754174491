import React, { useEffect } from 'react'
import HeroContent from "../Hero_component/Hero";
import AnnualReportComp from '../AnnualReport_component/AnnualReportComp';

const AnnualReport = () => {
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the component loads
    }, []);
  return (
    <>
     <HeroContent
        title="Annual Reaports"
        image={require("../../resource/Banner/report.jpg")}
      />
      <AnnualReportComp />
    </>
  )
}

export default AnnualReport