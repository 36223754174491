import React from "react";
import YouTube from "react-youtube";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./LifeAtSampurnaComp.css";

import { Autoplay } from "swiper/modules";

import image1 from "../../resource/LifeAtSSD/IMG_20230526_152839.jpg";
import image2 from "../../resource/LifeAtSSD/nailart.jpg";
import image3 from "../../resource/LifeAtSSD/WhatsApp Image 2022-11-14 at 5.12.50 PM (1).jpeg";
import image4 from "../../resource/LifeAtSSD/WhatsApp Image 2022-11-14 at 5.39.47 PM.jpeg";
import image5 from "../../resource/LifeAtSSD/WhatsApp Image 2023-06-15 at 12.59.32 PM.jpeg";

const LifeAtSSDComp = () => {
  const videoId = [
    {
      name: "Ruma Deb",
      designation: "Head - MIS",
      videoId: "QVCfDFZJO68",
    },
    {
      name: "Alpana Sil",
      designation: "Regional-Manager",
      videoId: "E78oFKvKvto",
    },
    {
      name: "Indira Roy",
      designation: "Senior L&D Manager",
      videoId: "Az5JpOf96fg",
    },
    {
      name: "Debi Dutta",
      designation: "Area Manager",
      videoId: "-lgQWDkZs84",
    },
    {
      name: "Pritam Dutta",
      designation: "Area Manager (Vocational Training)",
      videoId: "3lbM7mmaxn8",
    },
  ];

  return (
    <div className="container">
      <h4 className="headerText">Life @ Sampurna Skills</h4>
      {/* life@SSD intro */}
      <div className="intro">
        <div className="slide">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            lazy={"true"}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide className="swiper-slide">
              <img
                src={image1}
                loading="lazy"
                alt="life@ssd"
                className="slideImg"
              />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <img
                src={image2}
                loading="lazy"
                alt="life@ssd"
                className="slideImg"
              />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <img
                src={image3}
                loading="lazy"
                alt="life@ssd"
                className="slideImg"
              />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <img
                src={image4}
                loading="lazy"
                alt="life@ssd"
                className="slideImg"
              />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <img
                src={image5}
                loading="lazy"
                alt="life@ssd"
                className="slideImg"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="intro_text">
          <p className="text">
            At Sampurna Skills, the employee culture is characterized by a
            strong commitment to personal growth, collaborative teamwork, and a
            shared mission to empower communities through skill development.
            This culture Skills is one of dedication, growth, and community
            engagement, making it a dynamic and fulfilling place to work.
            Employees at Sampurna Skills are actively engaged with the
            communities they serve. This engagement not only helps in
            understanding the needs and challenges of the community but also
            fosters a sense of belonging and responsibility among the employees.
          </p>
        </div>
      </div>

      {/* youtube videos */}
      <div className="employee-speak">
        <h4 className="employee-speak-title">
          What Our Employees Say About Our Values
        </h4>
        <div className="employee-speak-content">
          {videoId.map((video) => {
            return (
              <YouTube
                videoId={video.videoId}
                iframeClassName="employee-video"
                title="Video Title"
                key={video.videoId}
              />
            );
          })}
        </div>
      </div>
      {/* employee gallery */}
      {/* <div className="employee-corner">
        <h4 className="headerText">Shining Stars</h4>
        <div className="employee-moments">
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>

          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
          <div className="employee-moments-item">
            <img src={image5} alt="employee-moments" className="moments"/>
            <h5 className="image-caption">Image Story</h5>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default LifeAtSSDComp;
