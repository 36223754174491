import React, { useEffect } from 'react'
import HeroContent from '../Hero_component/Hero';
import ComputerComp from '../ComputerComp/ComputerComp';

const Computer = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
       <HeroContent
        title="Computer"
        image={require("../../resource/Banner/branch.jpeg")}
      />
      <ComputerComp />
    </>
  )
}

export default Computer;
