import React from "react";
import "./CareerComp.css";
import Beauty from "../../resource/Career/Beauty.png";
import Computer from "../../resource/Career/Computer.jpg";
import English from "../../resource/Career/English.jpg";
import Dress from "../../resource/Career/Dress.png";
import Vocational from "../../resource/Career/Vocational.png";

const CareerComp = () => {
  return (
    <>
      <section className="career">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <h1>Computer Trainer</h1>
              <p>
                As a computer trainer, the candidate should be a tech-savvy.
                He/She needs to teach the students according to our syllabus and
                introduce them to the world of technologies.
              </p>
              <div className="responsibilities">
                <h4>Responsibilities</h4>
                <ol>
                  <li className="respon-list">
                    Create lesson plans for students
                  </li>
                  <li className="respon-list">Take time to time tests</li>
                  <li className="respon-list">
                    Ensure that the classroom stays orderly
                  </li>
                  <li className="respon-list">
                    Write progress reports for students
                  </li>
                  <li className="respon-list">Take practical tests</li>
                </ol>
              </div>
              <div className="qualification">
                <h4>Qualification</h4>
                <ol>
                  <li className="respon-list">
                    Graduate with specialisation or professional certification
                    in relevant field.
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-2">
              <img src={Computer} alt="" />
            </div>

            {/* <button onClick={() => setShowModal(true)}>Open Form</button>
            {showModal && (
              <div className="modal">
                <div className="modal-content">
                  <button
                    className="close-button"
                    onClick={() => setShowModal(false)}
                  >
                    &times;
                  </button>
                  <form>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                    />
                    <input type="tel"  value={number}
                      onChange={(e) => setNumber(e.target.value)} placeholder="Phone Number" />
                    <input type="text"  value={interested}
                      onChange={(e) => setInterested(e.target.value)} placeholder="Interested In" />
                    <button type="submit" onClick={applyMail}>Submit</button>
                  </form>
                </div>
              </div>
            )} */}
          </div>
          <div className="row">
            <div className="col-1">
              <h1>Spoken English Trainer</h1>
              <div className="responsibilities">
                <h4>Responsibilities</h4>
                <ol>
                  <li className="respon-list">
                    Create lesson plans for students
                  </li>
                  <li className="respon-list">
                    Grade student essays and tests
                  </li>
                  <li className="respon-list">
                    Ensure that the classroom stays orderly
                  </li>
                  <li className="respon-list">
                    Encourage students to speak out loud in class
                  </li>
                  <li className="respon-list">
                    Write progress reports for students
                  </li>
                  <li className="respon-list">
                    Assign essays and homework to students
                  </li>
                  <li className="respon-list">
                    Assign books for students to read
                  </li>
                </ol>
              </div>
              <div className="qualification">
                <h4>Qualification</h4>
                <ol>
                  <li className="respon-list">
                    Comfortable working with students of different levels
                  </li>
                  <li className="respon-list">
                    Comfortable working with students of different levels
                  </li>
                  <li className="respon-list">
                    Ability to create lesson plans
                  </li>
                  <li className="respon-list">Certified by the state board</li>
                  <li className="respon-list">
                    Ability to pass a background check
                  </li>
                  <li className="respon-list">Great interpersonal skills</li>
                  <li className="respon-list">Great grammar knowledge</li>
                  <li className="respon-list">
                    Knowledge about great works of literature
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-2">
              <img src={English} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <h1>Beauty and Makeup Trainer</h1>
              <p>
                As a Makeup Instructor, the candidate needs to teach cosmetology
                students the art of makeup application. He or she must be
                passionate about cosmetics and have typically spent time in the
                field as a Makeup Artist, which means your metaphorical makeup
                bag is filled with all the tricks of the trade. Now, you've been
                hired as a Makeup Instructor to share your expertise and shape
                future makeup professionals.
              </p>
              <div className="responsibilities">
                <h4>Responsibilities</h4>
                <ol>
                  <li className="respon-list">
                    Like any Instructor, you're expected to create curriculums,
                    and keep track of attendance and grades. This position gives
                    you the rare opportunity to continue expressing yourself
                    artistically, while also helping to pave the future of the
                    field you love.
                  </li>
                </ol>
              </div>
              <div className="qualification">
                <h4>Qualification</h4>
                <ol>
                  <li className="respon-list">
                    Graduate with specialisation or professional certification
                    in relevant field.
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-2">
              <img src={Beauty} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <h1>Dress Designing Trainer</h1>
              <p>
                Fashion design instructors help aspiring designers learn the ins
                and outs of fashion. They teach students how to navigate the
                often cutthroat and high-intensity world of the fashion
                industry. Oftentimes, fashion design instructors are former
                fashion designers who still want to be part of the fashion world
                and pass along their knowledge and skills to the next generation
                of designers.
              </p>
              <div className="responsibilities">
                <h4>Responsibilities</h4>
                <ol>
                  <li className="respon-list">
                    As the title suggests, the main job duty of a fashion design
                    instructor is to teach. Depending on the school, the
                    instructor may only teach one type of fashion course, or she
                    may teach a variety of different courses. Types of courses
                    fashion design instructors teach include an introduction to
                    fashion design, fashion history, apparel construction,
                    pattern making and textiles.
                    <br />
                    <br />
                    An instructor may also teach fashion drawing, sewing
                    techniques, event and fashion show production,
                    merchandising, fashion trends and digital design. Other job
                    duties include putting together lesson plans, evaluating
                    students, keeping office hours and participating in school
                    functions as needed.
                  </li>
                </ol>
              </div>
              <div className="qualification">
                <h4>Qualification</h4>
                <ol>
                  <li className="respon-list">
                    Graduate with specialisation or professional certification
                    in relevant field.
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-2">
              <img src={Dress} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <h1>Vocational Training Executive</h1>
              <div className="responsibilities">
                <h4>Responsibilities</h4>
                <ol>
                  <li className="respon-list">
                    Responsibilities of student admission
                  </li>
                  <li className="respon-list">
                    Marketing for different ongoing vocational courses
                  </li>
                  <li className="respon-list">
                    Organizing classrooms and maintaining the proper classroom
                    environment
                  </li>
                </ol>
              </div>
              <div className="qualification">
                <h4>Qualification</h4>
                <ol>
                  <li className="respon-list">
                    Graduate with specialisation or professional certification
                    in relevant field.
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-2">
              <img src={Vocational} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerComp;
