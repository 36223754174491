import React, { useEffect } from "react";
import HeroContent from "../Hero_component/Hero";
import RefundComp from "../RefundPolicy_component/RefundComp";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
      <HeroContent
        title="Refund Policy"
        image={require("../../resource/Banner/privacy_policy.jpg")}
      />
      <RefundComp />
    </>
  );
};

export default RefundPolicy;
