import React from "react";
import "./CancelComp.css";

const CancelComp = () => {
  return (
    <>
      <section className="cancle container">
        <ul>
          <li>
            <h3>Introduction:</h3>
            <p>
              This Course Cancellation Policy (the "Policy") outlines the
              guidelines and procedures for course cancellations at Sampurna
              Skills Development Pvt. Ltd. (referred to as "the Company" or
              "we"). We understand that unforeseen circumstances may arise, and
              this Policy aims to provide clarity and fairness to both our
              learners and the Company.
            </p>
          </li>
          <li>
            <h3>Cancellation by Learner:</h3>
            <ul>
              <li>
                <h5>Withdrawal:</h5>
                <ul>
                  <li>
                    If a learner wishes to withdraw from a course, they must
                    notify the Company in writing or by email at least [insert
                    number of days] days prior to the course start date.
                  </li>
                  <li>
                    The learner will be eligible for a refund according to the
                    refund policy stated in the terms and conditions provided at
                    the time of enrolment. Please refer to the terms and
                    conditions for specific refund amounts based on the
                    notification period.
                  </li>
                </ul>
              </li>
              <li>
                <h5>No Show: </h5>
                <ul>
                  <li>
                    If a learner fails to attend the course without prior notice
                    or withdraws after the course start date, no refund will be
                    provided.
                  </li>
                  <li>
                    Exceptions to this policy may be considered on a
                    case-by-case basis, subject to the Company's discretion and
                    the circumstances presented by the learner.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h3>Cancellation by the Company: </h3>
            <ul>
              <li>
                <h5>Minimum Enrolment Requirement: </h5>
                <ul>
                  <li>
                    The Company reserves the right to cancel a course if the
                    minimum enrolment requirement is not met.
                  </li>
                  <li>
                    In such cases, the Company will notify enrolled learners at
                    least [insert number of days] days prior to the course start
                    date and provide a full refund of any fees paid.
                  </li>
                </ul>
              </li>
              <li>
                <h5>Unforeseen Circumstances </h5>
                <ul>
                  <li>
                    In the event of unforeseen circumstances, such as instructor
                    unavailability or facility-related issues, the Company may
                    need to cancel or reschedule a course.{" "}
                  </li>
                  <li>
                    . The Company will make every effort to provide advance
                    notice of cancellation or rescheduling and offer alternative
                    options or refunds to affected learners.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h3>Rescheduling:</h3>
            <ul>
              <li>
                <h5>Company-initiated Rescheduling </h5>
                <ul>
                  <li>
                    If the Company needs to reschedule a course, enrolled
                    learners will be notified promptly.{" "}
                  </li>
                  <li>
                    The Company will offer alternative dates or times for the
                    course, and learners will have the option to transfer their
                    enrolment to the rescheduled session or receive a full
                    refund.
                  </li>
                </ul>
              </li>
              <li>
                <h5>Learner-initiated Rescheduling: </h5>
                <ul>
                  <li>
                    If a learner wishes to reschedule their course, they must
                    notify the Company in writing or by email at least [insert
                    number of days] days prior to the course start date.{" "}
                  </li>
                  <li>
                    The Company will make every effort to accommodate the
                    learner's request and provide alternative dates or times for
                    the course.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h3>Communication: </h3>
            <p>
              All course cancellation notifications, withdrawals, and
              rescheduling requests must be made in writing or by email to the
              following contact details: info@sampurna.co.in
            </p>
          </li>
          <li>
            <h3>Refund Process:</h3>
            <p>
              Refunds, if applicable, will be processed according to the
              Company's refund policy and the terms and conditions agreed upon
              at the time of enrolment. The refund process may take [insert
              number of days] days to complete.
            </p>
          </li>
          <li>
            <h3>Modifications to the Policy:</h3>
            <p>
              The Company reserves the right to modify or update this Course
              Cancellation Policy at any time. Any changes will be communicated
              to learners through our official communication channels and will
              be effective immediately upon posting.
            </p>
          </li>
        </ul>
        <p>
          By enrolling in a course with Sampurna Skills Development Pvt. Ltd.,
          learners acknowledge and agree to comply with this Course Cancellation
          Policy. <br />
          <br />
          Sampurna Skills Development Private Limited <br />
          DN-30, 3rd & 4th floor <br /> Saltlake City, Sector V. <br/> Kolkata -
                700091 <br />
          Ph no: 8100971969 <br />
        </p>
      </section>
    </>
  );
};

export default CancelComp;
