import React, { useEffect } from 'react';
import Hero from "../Hero_component/Hero.js";
import CenterComp from '../Center_component/CenterComp.js';

const OurCenter = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
      <Hero
        title="Our Centers"
        image={require("../../resource/Banner/branch.jpeg")}
      />
      <CenterComp />
    </>
  )
}

export default OurCenter;
