import React, { useEffect } from 'react';
import Hero  from "../Hero_component/Hero.js";
import BoardComp from "../Board_component/BoardComp.js";

function Board() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
    return (
      <>
      <Hero title ="Board" image={require('../../resource/Banner/board.png')}/>
      <BoardComp />
      </>
    );
  }
  
  export default Board;