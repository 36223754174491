import React, { useEffect } from "react";
import HeroContent from "../Hero_component/Hero";
import QuarterlyReports from "../QuarterlyReport_component/QuarterlyReports";


const QuarterlyReport = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
      <HeroContent
        title="Quarterly Reaports"
        image={require("../../resource/Banner/report.jpg")}
      />
      <QuarterlyReports />
    </>
  );
};

export default QuarterlyReport;
