import React, { useEffect } from 'react'
import HeroContent from '../Hero_component/Hero.js';
import OverviewComp from '../Overview_component/OverviewComp.js';

const Overview = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
      <HeroContent title="Overview"
        image={require("../../resource/Banner/skill.jpeg")} />
        <OverviewComp />
    </>
  )
}

export default Overview;
