import React, { useEffect } from 'react'
import TargetComp from '../TargetPeople_component/TargetComp'
import HeroContent from '../Hero_component/Hero'

const TargetPeople = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
    <HeroContent title="Target People"
        image={require("../../resource/Banner/targetpeople.jpg")} />
      <TargetComp />
    </>
  )
}

export default TargetPeople
