import React from "react";
import "./PrivacyComp.css";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const PrivacyComp = () => {
  return (
    <>
      <section className="privacy container">
        <div className="privacy_intro_para">
          <p>
            Sampurna Skills is concerned about privacy issues and wants you to
            be familiar with how we collect, use and disclose information.{" "}
            <br />
            <br />
            The following Privacy Policy describes our practices in connection
            with information that we collect through our browser and our
            software made available through the website (collectively, the
            “Service”). By providing Personal Information to us, you acknowledge
            receipt of this Privacy Policy.
          </p>
          <h3>Terms Meaning</h3>
          <ul>
            <li>
              The Terms “WE”, “OUR”, “US”, “COMPANY”, “FIRM”, “ORGANIZATION”
              refers to Sampurna Skills Development Pvt Ltd
              (www.sampurnaskills.co.in). <br />
              <br />
              <div style={{ marginLeft: "10px" }}>
                <h3>Address:</h3>
                <p>
                  3rd Floor (North), DN-30, Sector V, Salt Lake, Kolkata-700091.
                </p> 
                  <br />
                <p>Email At: info@sampurna.co.in</p>
              </div>
              User means any person or entity who is visiting/browsing our
              app/website, taking our services and using our app/website
              products and programs. Also referred as
              Customer/Client/Student/You.
            </li>
            <li>
              The term "SCHOOL", or "INSTITUTE" or "TRAINING CENTER" or
              "COACHING CLASS", or “BRANCH” or “CENTER', or "COACHING CENTER" -
              all refers to the company/firm/organization Sampurna Skills
              Development Pvt. Ltd.
            </li>
            <li>
              The Term "SERVICE" refers to the app and its features provided to
              deliver the training service to our students. The Service is
              designed to help instructors or training institute to use a range
              of learning tools with a closed group of their students, and to
              help instructors share their instructional content with their
              students and with others.
            </li>
          </ul>
        </div>
        <div className="privacy_overview">
          <h1>OVERVIEW</h1>
          <ul>
            <h3>About - Sampurna Skills :</h3>
            <li>
              We are a skill development organization that aims to foster
              development of the most vulnerable sections of the society. The
              target of our work is the economically disadvantaged families and
              we strongly believe that it is not merely enough to give access to
              such families to avenues of skills and finance to generate
              holistic development for them.
            </li>
            <li>
              Our app offers the learning services (like LMS) to student and
              management tools for our back-office staff.
            </li>
            <li>
              We limit the information we collect or maintain, how we use it,
              and who we share it with, to what is needed for this educational
              purpose.
            </li>
            <li>
              Our business model is simple - we charge student for the courses
              they subscribe from our institute/school. This app is completely
              free and no additional fees taken from student to use our app. We
              are not in the business of selling information to third-parties,
              or of marketing or advertising for third-parties.
            </li>
            <li>
              We apply protections for students under age 13 to address COPPA
              requirements, but almost all protections that we apply to child
              students we also extend to teen and adult students.
            </li>
          </ul>
        </div>
        <div className="table_of_content">
          <h2>Table of Contents</h2>
          <ul>
            <li>
              <ScrollLink
                to="about_the_website"
                smooth={true}
                offset={-50}
                duration={500}
              >
                About the Website
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="scope_policy"
              >
                Scope of this policy
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="personal_information"
              >
                Personal information
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="other_information"
              >
                Other information
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="choices_access"
              >
                Choices & Access
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="children"
              >
                Children - Under 13(COPPA)
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="advertising_marketing"
              >
                Advertising and Marketing
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="third_party_services"
              >
                Thrid party services
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="security"
              >
                Security
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="border_transfers"
              >
                Cross border transfers
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="change_policy"
              >
                Change to privacy policy
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                smooth={true}
                offset={-50}
                duration={500}
                to="report_abuse"
              >
                Contact us / Report Abuse
              </ScrollLink>
            </li>
          </ul>
        </div>
        <div className="about_the_website" id="about_the_website">
          <h1 className="privacy_para_heading">About the website</h1>
          <ul>
            <li>
              The core features of the website includes Student facilitation,
              Domain and Course Awareness, Fees Management, Report Management,
              Promotion, Placement Management, Career advertising, Communication
              and related features.
            </li>
            <li>
              The intended users of the website are -
              <ul>
                <li>Student - for learning activities</li>
                <li>
                  Teachers (teachers, trainers, corporate trainers, faculties,
                  master trainers, training managers) - for teaching
                </li>
                <li>
                  Parent/Guardian - for transparency on the institue's
                  performance and related activities
                </li>
                <li>
                  Backoffice office team - for handling the business operations
                </li>
                <li>
                  Admin - for handling the global permissions, reports and
                  analytics
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="scope_policy" id="scope_policy">
          <h1 className="privacy_para_heading">Scope of this policy</h1>
          <ul>
            <li>
              This Privacy Policy applies to information that we collect through
              the Service. The information collected in the system is, what's
              necessary for handling the training services (for instructor,
              student and backoffice staff) and meet the govt regulatory
              compliance.
            </li>
            <li>
              The section titled “Child Student Policy; COPPA” describes how our
              information policies differ for Child Students.
            </li>
            <li>
              If you access the Service through your School/Training Institute,
              then they may impose additional requirements on us or on you, such
              as requirements to comply with the School's/Institute's privacy
              policy or additional requirements imposed by your local or state
              government or central government.
            </li>
            <li>
              Individuals may separately follow us on, or share information
              through, third-party social media platforms. This Privacy Policy
              does not cover activities on those other platforms.
            </li>
          </ul>
        </div>
        <div className="personal_information" id="personal_information">
          <h1 className="privacy_para_heading">Personal Information</h1>
          <ul>
            <li>
              <h3>Personal Information We Collect</h3>
              <ul>
                <li>
                  The "personal information", the information which is used to
                  identity the student/instructor/staff member and communicate
                  them through. The detailed list is mentioned below, which we
                  may collect.
                  <ul>
                    <li>Name</li>
                    <li>Email</li>
                    <li>Contact Number</li>
                    <li>Photograph</li>
                    <li>Identify Proof / Student ID / UID /Aadhar Card</li>
                    <li>Local Address</li>
                    <li>Permanent Address</li>
                    <li>Father/Mother/Guardian Name</li>
                    <li>Guardian Email</li>
                    <li>Guardian Contact Number</li>
                  </ul>
                </li>
                <li>
                  If you submit any Personal Information relating to other
                  people to us in connection with the Service, you represent
                  that you have the authority to do so and to permit us to use
                  the information in accordance with this Privacy Policy.
                </li>
                <li>
                  We intend to only collect Personal Information that is
                  directly related to our delivery or improvement of the
                  Service.
                </li>
                <li>
                  We do not request, collect, or use geolocation data, health
                  data, biometric or genetic data, sensitive data (such as
                  relating to racial, ethnic, political opinion, philosophical
                  or religious belief, criminal background, trade-union
                  membership, or sexual orientation), or data regarding
                  participation in free or reduced lunch programs. We ask that
                  you not send us, and not disclose on or through the Service,
                  any such information.
                </li>
                <li>
                  We do not request, collect, or use behavioral data for any
                  sort of advertising or marketing purpose, or for any purpose
                  disallowed by COPPA
                </li>
                <li>
                  We do automatically collect usage data in the ordinary course
                  of operations, as discussed under{" "}
                  <Link
                    to="https://eisdigital.com/sbg/institute-privacy-policy/#Other-Information"
                    target="_blank"
                  >
                    “Other Information”
                  </Link>
                </li>
                <li>
                  We do request age information, and school/college/institute
                  affiliation, and treat that information as Personal
                  Information when we combine it with Personal Information.
                </li>
                <li>
                  We do not control, and cannot monitor, all information users
                  choose to include in user-created content.
                </li>
              </ul>
            </li>
            <li>
              <h3>How We May Collect Personal Information</h3>
              <p>
                we may collect Personal Information in a variety of ways,
                including:
              </p>
              <ul>
                <li>
                  <span style={{ fontWeight: 700 }}>Through the Service:</span>{" "}
                  We may collect Personal Information through the Service, such
                  as when you create an account or upload or submit information
                  (ex. course enquiry), or when you or an instructor uses voice
                  or video recording features or screen sharing type features.
                  Or you are registered/joined our organization as an employee.
                </li>
                <li>
                  <span style={{ fontWeight: 700 }}>
                    From Integrated Services:
                  </span>{" "}
                  If you decide to register for the Service through, or to
                  otherwise grant access to, a third-party social networking or
                  integrated service that we may make available (each, an
                  “Integrated Service”), such as Facebook Connect or Google, we
                  may obtain your name, email, and gender information from the
                  Integrated Service. You may revoke our access at any time by
                  updating the appropriate settings in the account preferences
                  of the relevant Integrated Service.
                </li>
              </ul>
            </li>
            <li>
              <h3>How We May Use Personal Information</h3>
              <p>
                We limit use of Personal Information to the following (and
                further limit its use with respect to Child Students as
                described under Child Students; COPPA):
              </p>
              <ul>
                <li>To provide the Service.</li>
                <li>
                  To respond to your inquiries and fulfil your requests, such as
                  to send you payment notification, batch schedule, new session
                  announcement.
                </li>
                <li>
                  To send administrative information to you, such as information
                  regarding the Service and changes to our terms, conditions and
                  policies.
                </li>
                <li>
                  To send to Instructors or students marketing communications
                  about the Service that we believe may be of interest. You can
                  unsubscribe to marketing communications by contacting our
                  back-office team.
                </li>
                <li>
                  For our internal operations business purposes, such as data
                  analysis, audits, fraud monitoring and prevention, developing
                  new products, enhancing, improving or modifying the Service,
                  identifying usage trends, determining the effectiveness of our
                  promotional campaigns and operating and expanding our business
                  activities.
                </li>
              </ul>
            </li>
            <li>
              <h3>How Personal Information May Be Disclosed</h3>
              <p>
                The Service includes access controls to restrict unauthorized
                access to Personal Information. We do not sell or rent Personal
                Information, and do not share it with third-parties for
                advertising or marketing purposes. We do not disclose Personal
                Information for research purposes. Except as further limited
                below with respect to Child Students, Personal Information may
                be disclosed for the purposes of delivering and improving the
                Service, in the event of a merger or acquisition, or as mandated
                by law, as follows:
              </p>
              <h5>For all Users:</h5>
              <ul>
                <li>
                  To our third-party service providers who provide services
                  supporting the internal operations of the Service, in each
                  case to the limited extent necessary, and subject to
                  assurances about protection and use. (For more details see
                  <Link
                    to="https://eisdigital.com/sbg/institute-privacy-policy/#Third-Party-Services"
                    target="_blank"
                  >
                    Third Party Services.
                  </Link>
                  )
                </li>
                <li>
                  To third-parties in the event of a reorganization, merger,
                  sale, joint venture, assignment, transfer or other disposition
                  of all or any portion of our business, assets or stock
                  (including in connection with any bankruptcy or similar
                  proceedings) (an “Acquisition”). Such third-parties may
                  include, for example, an acquiring entity and its advisors. We
                  will notify you if an Acquisition is completed where a
                  successor company will receive your information. This Privacy
                  Policy will continue to apply to your data transferred in an
                  Acquisition, and may be changed only in accordance with its
                  terms (see
                  <Link
                    to="https://eisdigital.com/sbg/institute-privacy-policy/#Change-to-this-Privacy-Policy"
                    target="_blank"
                  >
                    Changes to this Privacy Policy
                  </Link>
                  ).
                </li>
                <li>
                  If you access the Service through your School or institute's
                  subscription to the Service, then your School or institute may
                  have Administrative Users who can exercise a range of
                  administrative and oversight activities on behalf of your
                  School or institute, including potentially the ability to
                  access account information; access, disclose or restrict
                  information that you provided through that subscription; and
                  otherwise manage accounts under the School or institute
                  subscription, including changing user types, terminating
                  account access, or accepting or acknowledging changes to our
                  terms.
                </li>
                <li>
                  Your trainer will also have access about your submissions and
                  other activity through the Service, including, for example,
                  chat-conversation, responses to quizzes or polls, written
                  assignments, written discussion entries, audio captured (for
                  example in class discussions), and information submitted
                  through screen sharing features.
                </li>
                <li>
                  To your Course Mate Groups (Students of same course / in same
                  classroom). For example, submissions made through the
                  announcements, projects, or screen sharing type features are
                  visible to both your instructor and other Students in your
                  Course Mate Groups, possibly along with your name and photo.
                </li>
                <li>
                  By you, if you share publicly, for example on your public
                  profile page, or through other services to which you are able
                  to post information and materials. (Students do not have
                  public profiles, and do not have access to integrated features
                  for sharing through social media). Information you share
                  publicly may be available to other users and the general
                  public. We urge you to be very careful when deciding to share
                  any information outside the Service.
                </li>
              </ul>
              <h5>Compelled Disclosure</h5>
              <p>
                We may also use or disclose Personal Information as we believe
                to be necessary or appropriate:
              </p>
              <ul>
                <li>
                  under applicable law, including laws outside your country of
                  residence
                </li>
                <li>to comply with legal process</li>
                <li>
                  to respond to requests from public and government authorities,
                  including public
                </li>
                <li>to enforce our terms and conditions</li>
                <li>
                  to protect our rights, privacy, safety or property, and/or
                  that of our affiliates, you or others.
                </li>
              </ul>
              <p>
                If we are compelled by a government request or subpoena/summon
                to disclose Personal Information, we will use commercially
                reasonable efforts to notify you or your
                school/institute/college, to the extent permitted by law and by
                our contract with your school/college/institute (our customer)
                where applicable.
              </p>
              <h5>Disclosure by Others</h5>
              <p>
                We cannot guarantee how other users may use or share your
                information.
              </p>
            </li>
          </ul>
        </div>
        <div className="other_information" id="other_information">
          <h1 className="privacy_para_heading">Other Information</h1>
          <ul>
            <li>
              <h3>Other Information We Collect</h3>
              <p>
                “Other Information” is any information that does not, on its
                own, identify an individual, such as:
              </p>
              <ul>
                <li>Browser and device information</li>
                <li>
                  Information collected through cookies, pixel tags and other
                  technologies
                </li>
                <li>
                  Demographic information and other information provided by you
                  or your instructor (such as birth year and month, and school
                  affiliation, assessment responses, information you submit or
                  store in the Service as user-created content or through course
                  participation)
                </li>
                <li>Aggregated information</li>
              </ul>
            </li>
            <li>
              <h3>How We May Collect Other Information</h3>
              <p>
                We and our service providers may collect Other Information in a
                variety of ways, including:
              </p>
              <ul>
                <li>
                  Through your browser or device: Certain information is
                  collected by most browsers or automatically through your
                  device, such as your Media Access Control (MAC) address,
                  computer type (Windows or Mac), screen resolution, operating
                  system name and version, device manufacturer and model,
                  language and Internet browser type and version. We use this
                  information to ensure that the Service functions properly.
                </li>
                <li>
                  Using cookies: Cookies are pieces of information stored
                  directly on your device. Cookies allow us to collect
                  information such as browser type, time spent on the Service,
                  pages visited, language preferences and other traffic data. We
                  and our service providers use the information only to support
                  the internal operations of the Service, such as for security
                  purposes, to facilitate navigation, to display information
                  more effectively, to personalize your experience while using
                  the Service and to recognize your device to assist your use of
                  the Service. We also gather statistical information about use
                  of the Service in order to continually improve its design and
                  functionality, understand how it is used and assist us with
                  resolving questions regarding it. <br />
                  <br />
                  If you do not want information collected through the use of
                  cookies, there is a simple procedure in most browsers that
                  allows you to automatically decline cookies or be given the
                  choice of declining or accepting the transfer to your computer
                  of a particular cookie (or cookies) from a particular site.
                  If, however, you do not accept cookies, you may experience
                  some inconvenience in your use of the Service. For example, we
                  may not be able to recognize your computer, and you may need
                  to log-in every time you visit. <br />
                  <br />
                  We do not respond to browser “Do Not Track” signals at this
                  time.
                </li>
                <li>
                  Using pixel tags and other similar technologies: Pixel tags
                  (also known as web beacons and clear GIFs) may be used in
                  connection with the Service to, among other things, track the
                  actions of users (including in connection with the emails we
                  send), measure the success of marketing campaigns and compile
                  statistics about use of the Service and response rates.
                </li>
                <li>
                  Analytics: We use third-parties, including Google Analytics,
                  which use cookies and similar technologies to collect and
                  analyze information about use of the Service and report on
                  activities and trends, however we configure applicable
                  settings to disable marketing and advertising features.
                </li>
                <li>
                  IP Address: Your IP address is a number that is automatically
                  assigned to the computer that you are using by your Internet
                  service provider. An IP address may be identified and logged
                  automatically in our server log files whenever a user accesses
                  the Service, along with the time of the visit and the pages
                  visited. Collecting IP addresses is standard practice and is
                  done automatically by many websites, applications and other
                  services. We use IP addresses for the purposes of providing
                  support for the internal operations of the Service, such as
                  calculating usage levels, diagnosing server problems and
                  administering the Service. We may also derive your approximate
                  general location from your IP address.
                </li>
                <li>
                  From you: Information such as your communications preference
                  may be collected, assessment responses, user-created content,
                  or class participation.
                </li>
                <li>
                  By aggregating information: Aggregated Personal Information
                  does not personally identify you or any other user of the
                  Service.
                </li>
              </ul>
            </li>
            <li>
              <h3>How We May Use and Disclose Other Information</h3>
              <p>We may use and disclose Other Information for purpose like</p>
              <ul>
                <li>
                  if we are required by applicable law to treat Other
                  Information as Personal Information, we will treat it as
                  Personal Information.
                </li>
                <li>
                  we do not use user-created content for any purpose other than
                  as specifically licensed to us
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="choices_access" id="choices_access">
          <h1 className="privacy_para_heading">Choice and access</h1>
          <ul>
            <li>
              <h3>
                Your choices regarding our use and disclosure of your Personal
                Information
              </h3>
              <ul>
                <li>
                  In addition to the fact that Company already configures Google
                  Analytics settings to disable data collection for advertising,
                  all users can choose for their usage to not be tracked by
                  Google Analytics by installing Google's browser add-on
                </li>
                <li>
                  For Students, we have already limited our use and disclosure
                  of Personal Information to what is necessary for the purposes
                  of providing the Service to you and your school, and in the
                  event of an Acquisition or legal mandate. For Child Students,
                  we make no other disclosure. For Older Students, any other
                  disclosure is at your sole choice and control by generating
                  and sharing a URL Link.
                </li>
                <li>
                  If you are a trainer, you may opt out from receiving
                  marketing-related emails/sms from us on a going-forward basis
                  by clicking the unsubscribe link in any such email. We will
                  try to comply with your request as soon as reasonably
                  practicable. Please note that if you opt out of receiving
                  marketing-related emails from us, we may still send you
                  important administrative messages, from which you cannot opt
                  out
                </li>
              </ul>
            </li>
            <li>
              <h3>
                How you can access, change, export or suppress your Personal
                Information
              </h3>
              <ul>
                <li>
                  <span className="list_point_bold">
                    User Profile Information :
                  </span>{" "}
                  For Personal Information in a user's profile, if you are
                  registered using an email/sms, you may update, correct or
                  delete that Personal Information through your account settings
                  page. If you, or your child, is not registered using an email,
                  you can make a request through your school, or contact us.
                  Account profile data is very limited, and can be exported by
                  printing your account profile page.
                </li>
                <li>
                  <span className="list_point_bold">
                    User-Created Content :
                  </span>{" "}
                  If a user includes Personal Information within user-created
                  content, you can access, change or delete that information by
                  deleting or editing that content through the Service, except
                  where the content has been submitted to the Instructor for
                  grading, it can only be modified with Instructor's permission.
                  You can export or download your user-created content,
                  generally as a PDF/Image file
                </li>
                <li>
                  <span className="list_point_bold">
                    Student Assessment (Assignment and Exam Modules) Data :
                  </span>{" "}
                  For Student assessment data combined with Personal Information
                  of an identifiable Student, that data can be exported or
                  downloaded by the applicable trainer/faculty.
                </li>
                <li>
                  <span className="list_point_bold">
                    Process to Review, Delete, Terminate:
                  </span>{" "}
                  Authorized representatives of school/institute are permitted
                  to review Personal Information held on their behalf, in order
                  to verify accuracy, and to request its correction, update, or
                  deletion. If you are an Instructor or authorized school
                  administrator and would like to review the Personal
                  Information we have collected from your Student, or if you
                  would like to ask us to make no further use of, correct, or
                  delete, the Personal Information we have collected from your
                  Student, please contact us. In your request, please include
                  your name and the Student's name, and specify the action you
                  would like us to take. For the Student's protection, we may
                  need to verify your identity and relationship before
                  implementing your request.
                </li>
                <li>
                  <span className="list_point_bold">
                    Deletion of Information:
                  </span>{" "}
                  Personal Information will not be retained longer than it is
                  reasonably required to provide the Service and will be deleted
                  promptly when it is no longer reasonably required or upon
                  request, whichever is earlier, subject to any contractual
                  obligations to the institute or legal compliance if
                  applicable. Company generally intends to maintain Personal
                  Information for a period after a Student last accessed the
                  Service, to enable Students to return to earlier course work
                  for later reference, however we make no guarantee that user
                  information will be retained. Information may be permanently
                  lost if a school/institute, instructor or user terminates a
                  course or an account or end of subscription. <br />
                  <br />
                  There may also be residual information that will temporarily
                  remain within our databases and other records in back-up
                  systems which will be over-written in the ordinary course,
                  generally within 30 - 90 days. It may not be reasonably
                  possible to locate or delete recordings of screen shares, or a
                  given individual's voice or image from recordings of class
                  discussions, however, such information will also be deleted
                  when it is no longer necessary to deliver the Services for the
                  institute/school.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="children">
          <h1 className="privacy_para_heading">
            Children - under 13 years (COPPA)
          </h1>
          <ul>
            <li>
              <h3>Children and COPPA Generally</h3>
              <p>
                As our service/product is EdTech use by students and trainers in
                schools/coaching institutes, including elementary and middle
                schools, we are especially sensitive to protect Child Students
                and their Personal Information. <br />
                <br />
                This section describes how we treat Child Students differently
                from what is described above for Older Students, and is intended
                to also explain how we address the requirements of the
                Children's Online Privacy Protection Act (“COPPA”), including
                requirements for parental consent and our commitment to parents'
                ongoing rights regarding their child's Personal Information.{" "}
                <br />
                <br />
                The definitions of Personal Information and Other Information
                above applies also to Child Students (as defined above). <br />
                <br />
                This section describes how our information practices are
                different with respect to Child Students.
              </p>
            </li>
            <li>
              <h3>Parental Consent</h3>
              <p>
                COPPA requires that an operator of an online service not collect
                Personal Information from a child without the prior consent of
                their parent, which consent can be obtained directly from the
                parent or through the child's school or training institute.{" "}
                <br />
                <br />
                In our standard practices: For child - it is strongly
                recommended to use parent's contact details(email and mobile)
                for communication. Also we recommend parents to parallel login
                to their child account to continuously monitor the activities.{" "}
                <br />
                <br />
                Parental consent, as obtained through the school or institute,
                is limited to collection, use and disclosure of Personal
                Information required for delivery of the Service (i.e. for
                sharing with Instructor/school and classmates, and for “internal
                operations”), or in the event of an Acquisition, or as compelled
                by law, in each case subject to the restrictions described above
                in{" "}
                <Link
                  to="https://eisdigital.com/sbg/institute-privacy-policy/#How-Personal-Information-May-Be-Disclosed"
                  target="_blank"
                >
                  “How Personal Information May Be Disclosed”
                </Link>
                . Parental consent does not permit any other disclosure to
                third-parties. <br />
                <br />
                Parental consent can be withdrawn at any time, and Personal
                Information can be deleted, as described in{" "}
                <Link
                  to="https://eisdigital.com/sbg/institute-privacy-policy/#Choice-and-Access"
                  target="_blank"
                >
                  section 5
                </Link>{" "}
                of this Policy.
              </p>
            </li>
            <li>
              <h3>Required Information</h3>
              <p>
                To use the Service, an account is required. During the
                registration for a course, parent has to fill the physical form
                (on paper/tablet device) by their own, and he/she can decide/ask
                what's necessary to service operations. <br /> <br />
                Students do not have public account profiles and their
                information is not visible outside of their Course Mate Groups
                and authorized institution officials except as described for
                Older Students under section 6.5{" "}
                <Link
                  to="https://eisdigital.com/sbg/institute-privacy-policy/#Social-Interactions-and-Content-Sharing"
                  target="_blank"
                >
                  “Social Interactions and Content Sharing”
                </Link>
                .
              </p>
            </li>
            <li>
              <h3>Limited Use of Information</h3>
              <p>
                As required by COPPA for Child Students, and consistent with our
                business model for all Students, use of Personal Information of
                Students is solely for the use and educational purposes of the
                Instructor or school contracting with us (including through an
                Education Instructor), and for no other purpose. Our use of
                Personal Information of Students is limited to that educational
                or approved program context. Disclosure is also permitted in an
                Acquisition, or if legally required, in each case subject to the
                requirements stated in this Privacy Policy (see{" "}
                <Link
                  to="https://eisdigital.com/sbg/institute-privacy-policy/#How-Personal-Information-May-Be-Disclosed"
                  target="_blank"
                >
                  “How Personal Information May Be Disclosed”
                </Link>
                ).
              </p>
            </li>
            <li>
              <h3>Social Interactions and Content Sharing</h3>
              <p>
                Interactions within the Course Mate Groups are visible to, and
                can be moderated by, the Instructor/backoffice team. All
                interactions through the Service are logged. <br />
                <br />
                Child Students cannot use the Service to share their content or
                interact with individuals outside their Course Mate Groups,
                managed by the course Instructors. <br />
                <br />
                Older Students are generally subject to the same social
                interaction and content sharing restrictions as Child Students,
                except that Older Students (and Instructors) may be permitted to
                share their own user-created content with individuals outside of
                the Service. The Older Student is responsible for determining
                what information, and with whom, to share in this way. Sharing
                outside the Service is not available for Child Students
              </p>
            </li>
            <li>
              <h3>No In-app Purchases</h3>
              <p>
                In-app purchases are not available to Child Students or Teen
                Students. In-app purchases to upgrade accounts to access
                additional features or capacity may be available to Adult
                Students. Such upgrades are available to Child or Teen Students
                only through an upgrade by their institute/school or purchase by
                their parent.
              </p>
            </li>
            <li>
              <h3>Right to Review, Delete, Terminate</h3>
              <p>
                Upon request from the school/institute/parent, we will promptly
                provide a description of the types of Personal Information
                collected (also included in this Privacy Policy); an opportunity
                to review the Student's Personal Information and/or have the
                information deleted; and the opportunity to prevent further use
                or online collection of a Student's Personal Information. For
                information on the process to review, delete or terminate use of
                your child or Student's Personal Information, and for further
                information about retention and deletion of Personal
                Information, please see section 5.2{" "}
                <Link
                  to="https://eisdigital.com/sbg/institute-privacy-policy/#How-you-can-access"
                  target="_blank"
                >
                  “How you can access, change, export or suppress your Personal
                  Information”
                </Link>
                .
              </p>
            </li>
          </ul>
        </div>
        <div className="advertising_marketing" id="advertising_marketing">
          <h1 className="privacy_para_heading">ADVERTISING AND MARKETING</h1>
          <ul>
            <li>
              <h3>Students</h3>
              <p>
                We do not advertise or market to Students. <br />
                <br />
                The SMS send to students are all verified and approved by TRAI
                DLT System in India.
              </p>
            </li>
            <li>
              <h3>Instructors</h3>
              <p>
                We do not advertise or market third-party products or services
                to Instructors through the Service or using user email or other
                Personal Information. <br />
                <br />
                We may promote information about the Service to Instructors
                through our e-newsletter, through the Service, or using user
                emails, unless the Instructor unsubscribes as described below.
              </p>
            </li>
            <li>
              <h3>Ad Tracking</h3>
              <p>
                We do not use third-party advertising services, or ad tracking
                services or technologies, to display targeted advertising on
                third-party websites or services. We do not permit any
                third-party to use student data to create an automated profile,
                engage in data enhancement, conduct social advertising, or
                target advertising to students, parents, teachers or schools.
              </p>
            </li>
            <li>
              <h3>Unsubscribing</h3>
              <p>
                Instructor may opt-out of receiving our e-newsletters at any
                time by requesting for unsubscribe to our backoffice team.
              </p>
            </li>
          </ul>
        </div>
        <div className="third_party_services" id="third_party_services">
          <h1 className="privacy_para_heading">THIRD-PARTY SERVICES</h1>
          <ul>
            <li>
              <h3>Third-party Services Supporting Internal Operations</h3>
              <p>
                We use a limited number of third-party service providers, solely
                to support the internal operations of the Service, including for
                roles such as website hosting, data transfer, information
                technology and related infrastructure provision, software
                development, email delivery, sms delivery, auditing and other
                services. <br />
                <br />
                The categories of information that may be shared with service
                providers are limited to what is necessary, and are dependent on
                the specific services they are providing. In general, we do not
                disclose Personal Information from a user's account profile to
                service providers, and for nearly all service providers, we are
                able to significantly limit Personal Information that a
                service-provider might have access to. <br />
                <br />
                As a requirement for us sharing student Personal Information
                with a service provider, we require assurances that the service
                provider will collect, use, and share that information only in
                ways that are consistent with our commitments in this Privacy
                Policy. Among other things, we evaluate the service provider's
                ability to safeguard Personal Information, and require
                assurances that Personal Information of our users will be used
                and disclosed only on our behalf, and that service provider
                maintains reasonable data security practices. We do not allow
                service-providers to re-identify, augment, or combine our
                end-user's data with other data sources.
              </p>
            </li>
            <li>
              <h3>Third-party Authentication</h3>
              <p>
                The Service does enable login using third-party authentication
                integrations, for example to allow a user to log in to the
                Service using their email or social media account. The
                information we receive from login provides the name, profile
                picture, and email address (if available) provided by these
                services. If you use your email, social media, or federated
                login account to login to the Service, that third-party service
                will know that you have logged in to our Service. However, we do
                not share Personal Information with those authentication
                providers.
              </p>
            </li>
            <li>
              <h3>Services Accessed Through Links or Widgets</h3>
              <p>
                This Privacy Policy does not address, and we are not responsible
                for, the privacy, information or other practices of any
                third-parties, including any third-party operating any site or
                service to which the Service links. For example, this may be in
                the form of a link or embedded widget, including within content
                posted by another user. <br />
                <br />
                Where a user visits another site or service, the terms of use
                and privacy policies of that site or service apply, and not
                ours.
              </p>
            </li>
          </ul>
        </div>
        <div className="security" id="security">
          <h1 className="privacy_para_heading">SECURITY</h1>
          <ul>
            <li>
              We seek to use reasonable organizational, technical and
              administrative measures designed to protect the security and
              integrity of Personal Information within our organization
            </li>
            <li>
              We use TLS 1.2 (SSL) security at the network level to secure data
              exchange, including account information, streaming data, LMS
              contents.
            </li>
            <li>
              Our standard security practices are mentioned{" "}
              <Link
                to="https://eisdigital.com/secure-and-virus-free"
                target="_blank"
              >
                here
              </Link>
            </li>
            <li>
              The passwords are hashed. Internally, only authorized personnel
              have access to Personal Information you provide to us and each
              employee with access to Personal Information is obligated to
              maintain its integrity and confidentiality.
            </li>
            <li>
              Unfortunately, no data transmission or storage system can be
              guaranteed to be 100% secure. If you have reason to believe that
              your interaction with us is no longer secure (for example, if you
              feel that the security of your account has been compromised),
              please immediately notify us by{" "}
              <Link
                to="https://eisdigital.com/sbg/institute-privacy-policy/#Contact-us-report-abuse"
                target="_blank"
              >
                contacting us.
              </Link>
            </li>
            <li>
              In the event of a security breach, we will use reasonable measures
              to notify the affected account holders (which may be through their
              school or institute), and will notify relevant authorities, in
              each case to the extent required by applicable law or our
              agreement with the school or institute.
            </li>
          </ul>
        </div>
        <div className="border_transfers" id="border_transfers">
          <h1 className="privacy_para_heading">CROSS BORDER TRANSFERS</h1>
          <ul>
            <li>
              Our services are exclusively designed and served in India. We may
              have few students from outside India.
            </li>
            <li>
              The service is operated from India and is not intended to subject
              us to the laws or jurisdiction of any country other than that of
              the India
            </li>
            <li>
              Your Personal Information may be stored and processed in any
              country where we have facilities or in which we engage service
              providers, and, by using the Service, you consent to the transfer
              of information to countries outside of your country of residence,
              including the India, which may have data protection rules that are
              different from those of your country
            </li>
            <li>
              In certain circumstances, courts, law enforcement agencies,
              regulatory agencies or security authorities in those other
              countries may be entitled to access your Personal Information.
            </li>
            <li>
              If we are compelled by a government request or subpoena to
              disclose Personal Information, we will use commercially reasonable
              efforts to notify you or your school or institute, to the extent
              permitted by law and by our contract with your school/institute
              where applicable.
            </li>
          </ul>
        </div>
        <div className="change_policy" id="change_policy">
          <h1 className="privacy_para_heading">
            CHANGE TO THIS PRIVACY POLICY
          </h1>
          <p>
            The current version policy is present on this page and previous
            version policy link is provided on the top of this page. If we make
            any changes to this Privacy Policy, we will provide at least 30 days
            prior written notice. Notice will be send by email to the account
            holder email associated with the account, if applicable, and by
            displaying a prominent banner alerting users to the upcoming change,
            so that you have sufficient time to evaluate the change.
          </p>
        </div>
        <div className="report_abuse" id="report_abuse">
          <h1 className="privacy_para_heading">CONTACT US / REPORT ABUSE</h1>
          <p>
            If you have any query regarding this policy or anything which seems
            inappropriate, please do contact us through below options. <br />
            <br />
            <span>
              Address: <br />
              3rd Floor (North), DN-30, Sector V, Salt Lake, Kolkata-700091.{" "}
            </span>
            <br />
            <span style={{ color: "black" }}>
              Contact Us : info@sampurna.co.in
            </span>
            <br />
            The home page of the website (before login) or dashboard (after
            login) or about us page link contains the contact us details like
            email address, phone and postal address. You can reach us through
            any of the medium. Because email communications are not always
            secure, please do not include sensitive information in your emails
            to us.
          </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyComp;
