import React from 'react';
import "./CourseComp.css";
import {Link} from 'react-router-dom';

const CourseComp = () => {
  return (
    <>
         <section className="services">
         <h1 className="sec_heading">Our Courses</h1>
        <div className="container">
          <Link className="service_box" to="/Computer">
            <div className="service_content">
              <h5>Computer</h5>
              <p>Sampurna Skills offers a comprehensive range of computer courses designed to provide participants with practical skills and knowledge in various areas of computer technology.</p>
              <span>LEARN MORE</span>
            </div>
          </Link>
          <Link className="service_box" to="/MakeupBeauty">
            <div className="service_content">
              <h5>Makeup & Beauty</h5>
              <p>Sampurna offers a diverse range of makeup and beauty courses designed to provide participants with professional skills in the beauty industry.</p>
              <span>LEARN MORE</span>
            </div>
          </Link>
          <Link className="service_box" to="/DressDesigining">
            <div className="service_content">
              <h5>Dress Designing</h5>
              <p>The Certificate in Basic Dress Designing is a specialized program that focuses on developing fundamental skills in dress design and construction. </p>
              <span>LEARN MORE</span>
            </div>
          </Link>
          <Link className="service_box" to="/SpokenEnglish">
            <div className="service_content">
              <h5>Spoken English</h5>
              <p>The Certificate in Basic Dress Designing is a specialized program that focuses on developing fundamental skills in dress design and construction. </p>
              <span>LEARN MORE</span>
            </div>
          </Link>
        </div>
      </section>
    </>
  )
}

export default CourseComp;