import React, { useEffect } from "react";
import HeroContent from "../Hero_component/Hero";
import GrievanceComp from "../GrievanceComp/GrievanceComp";

const GrievanceRedressal = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);
  return (
    <>
      <HeroContent
        title="Grievance Redressal"
        image={require("../../resource/Banner/grievance.jpg")}
      />
      <GrievanceComp />
    </>
  );
};

export default GrievanceRedressal;
