import React, { useState, useEffect } from "react";
import "./AnnualReportComp.css";
import { Link } from "react-router-dom";

const AnnualReportComp = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetch("https://manage.sampurnaskills.co.in/api/AnnualyReports")
      .then((response) => response.json())
      .then((data) => setReports(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="quarterlyreports annualyreports container">
      {reports.map((report, index) => (
        <Link
          to={report.pdfPath}
          target="_blank"
          className="reports"
          key={index}
        >
          <img
            src={report.imgPath}
            alt="pic"
          />
          <span>{report.name}</span>
        </Link>
      ))}
    </div>
  );
};

export default AnnualReportComp;
